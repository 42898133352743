import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Button, ComboboxItem, Input, Loader, Modal, Tooltip } from 'components'

import styles from './styles.module.scss'
import { useForm } from 'react-hook-form'
import { Combobox } from 'components/ComboboxV2/Combobox'
import { parseDataToComboboxV2 } from 'utilities/combobox'

import { useGetUsers } from 'shared/hooks/services'
import { useDebounce, useToast, useToggle } from 'shared/hooks'

import { joiResolver } from '@hookform/resolvers/joi'

import { ReactComponent as Info } from 'assets/svg/information.svg'

import {
  TechnicianScheduleForm,
  formSchema,
} from '../../schemas/technicianScheduleForm'

import { calcTimestamp, ensureMilliseconds } from './utils'
import DateConfig from './components/DateConfig'
import { TechnicianSchedulePayload } from 'services/technicianSchedule/types'
import {
  useCreateTechnicianSchedulePause,
  useEditTechnicianSchedulePause,
} from 'services/technicianSchedule'
import { POSITION } from 'shared/hooks/useElementPosition/types'
import { useGetServiceOrders } from 'shared/hooks/serviceOrder/useGetServiceOrders'
import { usePersistentFilters } from 'shared/hooks/usePersistentFilters/usePersistentFilters'

import { usePersistentTab } from 'domains/occurrence/hooks'
import { currentPeriod } from '../../utils'
import { paths } from 'routes'

export interface TechnicianScheduleModalProps {
  technicianScheduleId?: string
  data?: TechnicianScheduleForm
  onSave: () => void
  onClose: () => void
  isVisible: boolean
}

const Form: FC<TechnicianScheduleModalProps> = ({
  technicianScheduleId,
  data,
  onSave,
  onClose,
  isVisible,
}) => {
  const { register, watch, setValue, handleSubmit, formState, reset } =
    useForm<TechnicianScheduleForm>({
      mode: 'onChange',
      resolver: joiResolver(formSchema),
    })

  const { addToast } = useToast()
  const tooltip = useToggle()
  const buttonRef = useRef<HTMLDivElement>(null)

  const [filter, setFilter] = useState('')
  const handleFilter = useDebounce(setFilter)

  const {
    isError: isErrorTechnicians,
    isFetching: isFetchingTechnicians,
    users: technicians,
    fetchNextUsersPage: fetchNextTechniciansPage,
  } = useGetUsers({ ...(!!filter && { name: filter }) })

  const { data: technicianServiceOrders } = useGetServiceOrders(
    {
      ...(watch('technician')?.id && {
        technicianIds: [watch('technician').id],
      }),
      status: ['SCHEDULED'],
      scheduleDateFrom: watch('startDate'),
      scheduleDateTo: watch('endDate'),
    },
    !!watch('technician')?.id && !!watch('startDate') && !!watch('startDate'),
  )

  const { mutate: create, isPending: createIsLoading } =
    useCreateTechnicianSchedulePause()
  const { mutate: edit, isPending: editIsLoading } =
    useEditTechnicianSchedulePause(technicianScheduleId)

  const isEditing = useMemo(
    () => !!technicianScheduleId,
    [technicianScheduleId],
  )

  const { replaceUrl } = usePersistentTab('view-service-order')

  const { setFilters } = usePersistentFilters('serviceOrder')

  const handleClose = () => {
    reset({})
    onClose()
  }

  const handleSelectedDates = useCallback(
    (startDate: number, startTime: string) => {
      const { endDate, endTime } = watch()

      if (endDate) {
        const formattedEndDate = calcTimestamp(endDate, endTime || '23:59')

        const formattedStartDate = calcTimestamp(startDate, startTime)

        if (formattedStartDate > formattedEndDate) {
          setValue('endDate', undefined, { shouldValidate: true })
          setValue('endTime', undefined, { shouldValidate: true })
        }
      }
    },
    [setValue, watch],
  )

  const handleOnInspectSO = useCallback(() => {
    const { technician, startDate, endDate } = watch()

    setFilters({
      technician,
      scheduleDateFrom: startDate,
      scheduleDateTo: endDate,
    })

    replaceUrl(paths.serviceOrder.search + '?page=1')
  }, [watch, setFilters, replaceUrl])

  const onSubmit = useCallback(
    (formData: TechnicianScheduleForm) => {
      const startDate = calcTimestamp(
        formData.startDate,
        formData.startTime || '00:00',
      )

      const endDate = calcTimestamp(
        formData.endDate,
        formData.endTime || '23:59',
      )

      const payload: TechnicianSchedulePayload = {
        ...(!isEditing && {
          technicianId: watch('technician').id,
        }),
        reason: formData.reason,
        note: formData.note,
        startDate: ensureMilliseconds(startDate),
        endDate: ensureMilliseconds(endDate),
      }

      const formFunction = isEditing ? edit : create

      formFunction(payload, {
        onSuccess: () => {
          addToast({
            type: 'success',
            message: `Bloqueio de agenda ${
              isEditing ? 'editado' : 'cadastrado'
            } com sucesso.`,
          })

          onSave()
        },
        onError: () => {
          addToast({
            type: 'alert',
            message: `Erro ao  ${
              isEditing ? 'editar' : 'cadastrar'
            } bloqueio. Tente novamente.`,
          })
        },
      })
    },
    [onSave, addToast, create, edit, isEditing, watch],
  )

  useEffect(() => {
    if (data) {
      Object.entries(data).map(([key, value]) => {
        return setValue(key, value, {
          shouldValidate: true,
          shouldDirty: false,
        })
      })
    }
  }, [data, setValue])

  return (
    <Modal
      isVisible={isVisible}
      title="Informar bloqueio de agenda do técnico"
      onClose={handleClose}
      size="md"
    >
      <Loader isVisible={createIsLoading || editIsLoading} />
      <div className={styles.container}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.content}>
            <Combobox
              {...register('technician')}
              id="technician-input"
              label={{
                text: 'Técnico',
              }}
              onSearch={(value) => {
                if (value === '') {
                  setValue('technician', undefined)
                  return
                }

                handleFilter(value)
              }}
              items={parseDataToComboboxV2(
                technicians.map((technician) => ({
                  id: technician.id,
                  name: technician.name,
                })) || [],
                'name',
              )}
              value={watch('technician')?.name || ''}
              onChange={(selected) => {
                const technician = selected as ComboboxItem<{
                  id: string
                  name: string
                }>

                if (technician) {
                  setValue('technician', technician.value, {
                    shouldValidate: true,
                    shouldDirty: true,
                  })
                }
              }}
              isLoading={isFetchingTechnicians}
              isError={isErrorTechnicians}
              onEndReached={fetchNextTechniciansPage}
              disabled={isEditing}
            />

            <Input
              label="Motivo"
              {...register('reason')}
              maxLength={100}
              onChange={(event) =>
                setValue('reason', event.target.value, {
                  shouldValidate: true,
                  shouldDirty: true,
                })
              }
            />

            <div className={styles.dateGroup}>
              <DateConfig
                label="Início do bloqueio"
                date={watch('startDate')}
                {...register('startDate')}
                {...register('startTime')}
                time={watch('startTime')}
                onChange={({ date, time }) => {
                  setValue('startDate', date, {
                    shouldValidate: true,
                    shouldDirty: true,
                  })
                  setValue('startTime', time, {
                    shouldValidate: true,
                    shouldDirty: true,
                  })

                  if (date) handleSelectedDates(date, time || '00:00')
                }}
                {...(currentPeriod(watch('startDate'), watch('endDate')) && {
                  disableButtons: ['immediate', 'from', 'date', 'hourButton'],
                })}
              />

              <DateConfig
                label="Retorno do bloqueio"
                date={watch('endDate')}
                {...register('endDate')}
                {...register('endTime')}
                time={watch('endTime')}
                onChange={({ date, time }) => {
                  setValue('endDate', date, {
                    shouldValidate: true,
                    shouldDirty: true,
                  })
                  setValue('endTime', time, {
                    shouldValidate: true,
                    shouldDirty: true,
                  })
                }}
                {...((!data?.technician ||
                  calcTimestamp(
                    watch('startDate'),
                    watch('startTime') || '00:00',
                  ) > Date.now()) && {
                  disableButtons: ['immediate'],
                })}
              />
            </div>

            {!!technicianServiceOrders?.totalElements && (
              <div className={styles.errorContainer}>
                <div className={styles.info}>
                  <Info />
                  <span>
                    O técnico selecionado possui ordens de serviço agendadas
                    para o período.
                  </span>
                </div>
                <button type="button" onClick={handleOnInspectSO}>
                  ACESSAR
                </button>
              </div>
            )}

            <div>
              <label className={styles.observationsLabel}>Observações</label>
              <textarea
                rows={8}
                maxLength={1000}
                id="schedule-note"
                {...register('note')}
                className={styles.textArea}
                value={watch('note') || ''}
                onChange={(event) =>
                  setValue('note', event.target.value, {
                    shouldValidate: true,
                    shouldDirty: true,
                  })
                }
              />
            </div>

            <Modal.Footer className={styles.footer}>
              <div
                ref={buttonRef}
                onPointerEnter={tooltip.show}
                onPointerLeave={tooltip.hide}
              >
                <Button
                  width="172px"
                  type={formState.isDirty ? 'cancel-secondary' : 'secondary'}
                  onClick={onClose}
                  buttonTitle="Voltar"
                />

                <Tooltip
                  isVisible={tooltip.isVisible && formState.isDirty}
                  type="informative"
                  positionAbove={POSITION.TOP}
                  parentRef={buttonRef}
                >
                  <p>
                    {`Existem informações que não foram salvas. Deseja realmente cancelar?`}
                  </p>
                </Tooltip>
              </div>
              <Button
                width="172px"
                type="primary"
                htmlType="submit"
                disabled={!formState.isValid}
                buttonTitle="Salvar"
              />
            </Modal.Footer>
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default Form
